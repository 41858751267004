<ng-container *transloco="let t">
  <fa-icon class="language-picker__icon" [icon]="dropdownIcon" size="xs"></fa-icon>
  <select
    #lang
    class="language-picker__select"
    (change)="onLanguageChanged(lang.value); lang.blur()"
    [attr.aria-label]="t('navbar.changeLang')"
  >
    @for (lang of languages$ | async; track lang) {
      <option [selected]="localeService.getActiveLang() === lang" [value]="lang">
        {{ lang.toString().toUpperCase() }}
      </option>
    }
  </select>
</ng-container>
