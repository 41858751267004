@if (languages$ | async; as languages) {
  <div class="space-x-xs">
    @for (lang of languages; track lang; let i = $index) {
      <span class="inline-block w-[37px] cursor-pointer" (click)="onLanguageChanged(lang)">
        <span
          class="inline-block w-full text-center font-semibold"
          [class.lang-toggle-selected]="localeService.getActiveLang() === lang"
        >
          {{ lang.toString().toUpperCase() }}
        </span>
      </span>
    }
  </div>
}
