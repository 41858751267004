import {Component, Inject} from '@angular/core'
import {LanguagePickerBaseComponent} from './language-picker-base.component'
import {TranslocoService} from '@ngneat/transloco'
import {LanguageService} from '../../../i18n/language.service'
import {LinkService} from '../../../i18n/link.service'

@Component({
  selector: 'bgo-language-picker-list',
  templateUrl: './language-picker-list.component.html',
  styleUrls: ['./language-picker-list.component.css'],
})
export class LanguagePickerListComponent extends LanguagePickerBaseComponent {
  constructor(
    @Inject(TranslocoService) localeService: TranslocoService,
    languageService: LanguageService,
    linkService: LinkService,
  ) {
    super(localeService, languageService, linkService)
  }
}
