import {Component, Inject} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {LanguageService} from '../../../i18n/language.service'
import {LinkService} from '../../../i18n/link.service'
import {LanguagePickerBaseComponent} from './language-picker-base.component'
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown'

@Component({
  selector: 'bgo-language-picker-dropdown',
  templateUrl: './language-picker-dropdown.component.html',
  styles: [
    `
      :host {
        @apply relative;
      }

      .language-picker__icon {
        @apply absolute right-[0.25rem] top-[0.125rem] px-xs;
      }
      .language-picker__select {
        @apply relative cursor-pointer;
        @apply appearance-none rounded bg-transparent;
        @apply py-2xs pl-xs pr-xl;
        @apply hover:bg-black/10;
      }

      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) :host {
        @apply text-white;
      }
      :host-context(.layout-navbar--transparent:not(.layout-navbar--scrolled)) .language-picker__select:hover {
        @apply bg-white/10;
      }
    `,
  ],
})
export class LanguagePickerDropdownComponent extends LanguagePickerBaseComponent {
  readonly dropdownIcon = faCaretDown
  constructor(
    @Inject(TranslocoService) localeService: TranslocoService,
    languageService: LanguageService,
    linkService: LinkService,
  ) {
    super(localeService, languageService, linkService)
  }
}
