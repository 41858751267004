import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {LanguagePickerListComponent} from './language-picker-list.component'
import {LanguagePickerDropdownComponent} from './language-picker-dropdown.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {TranslocoModule} from '@ngneat/transloco'

@NgModule({
  declarations: [LanguagePickerListComponent, LanguagePickerDropdownComponent],
  imports: [CommonModule, FontAwesomeModule, TranslocoModule],
  exports: [LanguagePickerListComponent, LanguagePickerDropdownComponent],
})
export class LanguagePickerModule {}
