import {Inject} from '@angular/core'
import {TranslocoService} from '@ngneat/transloco'
import {LanguageService} from '../../../i18n/language.service'
import {LinkService} from '../../../i18n/link.service'
import {Language} from '../../../i18n/language'
import {map} from 'rxjs/operators'

export class LanguagePickerBaseComponent {
  readonly languages$ = this.languageService.supportedLanguages$.pipe(
    map(languages => languages.sort((a, b) => (a > b ? 1 : -1))),
  )

  constructor(
    @Inject(TranslocoService) public readonly localeService: TranslocoService,
    private readonly languageService: LanguageService,
    private readonly linkService: LinkService,
  ) {}

  onLanguageChanged(newLanguage: string): void {
    // hard nav on lang change, as some localised content may come from the backend
    const navigateTo = (dest: string) => (window.location.href = dest)
    navigateTo(this.linkService.localizedPathToCurrentLocation(newLanguage as Language))
  }
}
